
import './App.css';
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useEffect, lazy, useState } from 'react';
import Navbar from './Assets/Common/navBar';
import Footer from './Assets/Common/footer';
// import Homepage from './Pages/HomePage';
// import EnterpriseSolutions from './Pages/EnterpriseSolution';
// import About from './Pages/About';
// import NxMap from './Pages/NxMap';
// import NxOps from './Pages/NxOps';
// import NxWorld from './Pages/NxWorld';
// import Contact from './Pages/Contact';
// import Error from './Pages/Error';
import { useNavigate } from "react-router-dom";
import CarbonFootprnt from './Pages/Posts/carbonFootprint';
import BusinessWorkshop from './Pages/Posts/businessWorkshop';
import Privacy from './Pages/Privacy';
import Terms from './Pages/TermsofUse';
import ModalBox from './Pages/ModalBox/ModalBox';
import { Cookies } from 'react-cookie';
import { deleteAllCookies,getUserConsent } from './analytic';
import { getByLabelText } from '@testing-library/react';
// import Loading from './Pages/Loading';

const Homepage = lazy(()=> import('./Pages/HomePage'))
const EnterpriseSolutions = lazy(()=> import('./Pages/EnterpriseSolution'))
const About = lazy(()=> import('./Pages/About'))
const NxMap = lazy(()=> import('./Pages/Nxmap2'))
const NxOps = lazy(()=> import('./Pages/NxOps'))
const NxWorld = lazy(()=> import('./Pages/NxWorld'))
const NxPlan = lazy(()=> import('./Pages/NxPlan'))
const Contact = lazy(()=> import('./Pages/Contact'))
const Loading = lazy(()=> import('./Pages/Loading'))
const News = lazy(()=> import('./Pages/News'))
const Evercomm = lazy(()=> import('./Pages/Evercomm10'))
const Error = lazy(()=> import('./Pages/Error'))
const PostOne = lazy(()=> import('./Pages/Posts/mitsu_evercomm'))
const PostTwo = lazy(()=> import('./Pages/Posts/nxmap'))
const PostThree = lazy(()=> import('./Pages/Posts/co2world'))
const PostFour = lazy(()=> import('./Pages/Posts/bureauVeritas'))
const PostFive = lazy(()=> import('./Pages/Posts/digitalEdge'))
const FinTech = lazy(()=> import('./Pages/Posts/FinTechFestival'))
const COP28 = lazy(()=> import('./Pages/Posts/cop28'))
const WorkPlan = lazy(()=> import('./Pages/Posts/work_plan_2024'))
const BCorp = lazy(()=> import('./Pages/Posts/bCorpCertified'))
const TrainerProgramme = lazy( ()=> import('./Pages/Posts/trainerProgramme'))
const PartnerWithSYSCOM = lazy( ()=> import('./Pages/Posts/partnerWithSYSCOM'))
const ThaiFex = lazy( ()=> import('./Pages/Posts/thaiFex'))
const ClimateTech = lazy( ()=> import('./Pages/Posts/climateTech'))
const IMEX = lazy( ()=> import('./Pages/Posts/imexFrankfurt') )
const GreenTechAccelerator = lazy( ()=> import('./Pages/Posts/greenTechAccelerator'))
const BusinessTime = lazy( ()=> import('./Pages/Posts/businessTime'))
const NDP2024 = lazy( ()=> import('./Pages/Posts/ndp2024'))
const PartnerWithCp = lazy( ()=> import('./Pages/Posts/partnerWithCP'))
const CTBCNetZero = lazy( ()=> import('./Pages/Posts/ctbcNetZero'))
const TeamBuilding = lazy(()=> import('./Pages/Posts/teamBuildingEvent'))



const cookies=new Cookies()
const userConsent = cookies.get('user_consent')

const components = [
  {
    path: "/home",
    component: <Homepage />,
    content: "Illuminating your energy & carbon performance in line with ISO 14064-1"
  },
  {
    path: "/enterprise-solutions",
    component: <EnterpriseSolutions />,
    content: "Welcome to the NX dimension."
  },
  {
    path: "/about",
    component: <About title="About" />,
    content: "Practising verifiable sustainability will do more than just help the climate, it will make businesses thrive."
  },
  {
    path: "/enterprise-solutions/NXMap",
    component: <NxMap />,
    content: "Entry-level emissions illumination for scope 1, 2 & 3"
  },
  {
    path: "/enterprise-solutions/NXOps",
    component: <NxOps />,
    content: "Real-time monitoring of energy efficiencies and carbon footprint value"
  },
  {
    path: "/enterprise-solutions/NXWorld",
    component: <NxWorld />,
    content: "Thriving in a sustainability-connected eco-system"
  },
  {
    path: "/enterprise-solutions/NXPlan",
    component: <NxPlan />,
    content: "Bring about the new reality"
  },
  {
    path: "/contact",
    component: <Contact />,
    content: "It all starts with a conversation."
  },
  {
    path: "/news",
    component: <News />,
    content: "News post for Evercomm"
  },
  // {
  //   path: "/Evercomm10",
  //   component: <Evercomm />,
  //   content: "Celebrating 10 momentous year"
  // },
  {
    path: "/2023/09/mitsubishi-electric-and-evercomm-enter-into-cooperative-agreement",
    component: <PostOne />,
    content: "Mitsubishi and Evercomm enter into cooperative-agreement"
  },
  {
    path: "/2023/09/evercomm’s-NXMap-platform-highlighted-by-enterprise-singapore-director-general-in-quality-and-standards-conference-2023",
    component: <PostTwo />,
    content: "Evercomm’s NXMap platform highlighted by enterprise Singapore and standards conference 2023"
  },
  {
    path: "/2023/09/partnering-towards-carbon-neutral-world-for-all",
    component: <PostThree />,
    content: "Partnering towards carbon-neutral world for all"
  },
  {
    path: "/2023/11/bureau-veritas-joins-forces-with-evercomm-to-help-businesses-tackle-decarbonisation-goals-through-digital-verification-and-training",
    component: <PostFour />,
    content: "Bureau-veritas joins forces with evercomm"
  },
  {
    path: "/2023/11/evercomm-congratulates-digital-edge-for-its-first-green-loan-success-for-a-data-center-in-seoul-korea",
    component: <PostFive />,
    content: "Evercomm congratulates digital-edge for its first green loan in seoul, korea"
  },
  {
    path: "/2023/12/singapore-fintech-festival-2023-event-highlights",
    component: <FinTech />,
    content: "Singapore FinTech Festival 2023 Event Highlights"
  },
  {
    path: "/2023/12/evercomm-awarded-unfcc-cop28-uae-techsprint-award",
    component: <COP28 />,
    content: "Evercomm awarded UNFCCC COP28 UAE TechSprint award"
  },
  {
    path: "/2024/01/gaining-momentum-workplan-and-retreat-2024",
    component: <WorkPlan />,
    content: "Gaining Momentum - Workplan & Retreat 2024"
  },
  {
    path: "/2024/02/our-commitment-to-using-business-as-a-force-for-good",
    component: <BCorp />,
    content: "Our commitment to using business as a force for good"
  },
  {
    path: "/2024/03/carbon-footprint-business-opportunity-for-the-future",
    component: <CarbonFootprnt />,
    content: "Carbon Footprint: Business Opportunity for the Future"
  },
  {
    path: "/2024/04/navigating-climate-policies-and-carbon-reporting-and-management-for-future-success",
    component: <BusinessWorkshop />,
    content: "Navigating Climate Policies and Carbon Reporting and Management for Future Success"
  },
  {
    path: "/2024/05/train-the-trainers-programme-in-partnership-with-panyapiwat-institute-of-management",
    component: <TrainerProgramme />,
    content: "Train-the-Trainers Programme in Partnership with Panyapiwat Institute of Management"
  },
  {
    path: "/2024/05/effective-carbon-emissions-reduction-monitoring-strategies-with-SYSCOM-group",
    component: <PartnerWithSYSCOM />,
    content: "Effective Carbon Emissions Reduction & Monitoring Strategies with SYSCOM Group"
  },
  {
    path: "/2024/06/thaifex-anuga-asia-2024-event-highlights",
    component: <ThaiFex />,
    content: "THAIFEX - Anuga Asia 2024 Event Highlights"
  },
  {
    path: "/2024/06/evercomm-awarded-top-100-climate-tech-startups-in-the-indo-pacific-region",
    component: <ClimateTech />,
    content: "Evercomm Awarded Top 100 Climate Tech Startups in the Indo-Pacific region by HolonIQ"
  },
  {
    path: "/2024/06/evercomm-congratulates-singapore-tourism-board-for-achieving-carbon-neutrality-at-IMEX-frankfurt-2024",
    component: <IMEX />,
    content: "Evercomm Congratulates Singapore Tourism Board for Achieving Carbon-Neutrality at IMEX Frankfurt 2024"
  },
  {
    path: "/2024/07/greenTech-accelerator-2024-by-UOB-FinLab",
    component: <GreenTechAccelerator />,
    content: "GreenTech Accelerator 2024 by UOB FinLab"
  },
  {
    path: "/2024/07/the-business-times-feature",
    component: <BusinessTime />,
    content: "The Business Times Feature, 17 July 2024"
  },
  {
    path: "/2024/08/ndp-2024-a-carbon-conscious-celebration",
    component: <NDP2024 />,
    content: "NDP 2024 a Carbon-conscious Celebration"
  },
  {
    path: "/2024/09/evercomm-partners-with-CP-group-corporate-university-programme",
    component: <PartnerWithCp />,
    content: "Evercomm Partners with C.P. Group’s Corporate University Programme"
  },
  {
    path: "/2024/09/evercomm-unveils-new-features-for-financed-emission-management-at-ctbc-net-zero-sustainability-forum",
    component: <CTBCNetZero />,
    content: "Evercomm Unveils New Features for Financed Emission Management at CTBC’s Net-Zero Sustainability Forum"
  },
  {
    path: "/2024/10/day-of-learning-teamwork-and-creativity-at-evercomm",
    component: <TeamBuilding />,
    content: "A Day of Learning, Teamwork, and Creativity at Evercomm"
  },
  {
    path: "/privacy",
    component: <Privacy />,
    content: "Privacy"
  },
  {
    path: "/terms-of-use",
    component: <Terms />,
    content: "Terms of use"
  },
  {
    path: "*",
    component: <Error />,
    content: "Error"
  },
]
function App() {
  const location = useLocation();
  useEffect(()=>{

    if(userConsent != true && userConsent != false){
      document.querySelector('.modalBoxBtn')?.click()
    }
  },[userConsent])

  useEffect(()=>{
    if(userConsent == undefined){
      deleteAllCookies()
    }
  },[userConsent])
  


  const navigate = useNavigate();
  const [height, setHeight] = useState('')
  useEffect(() => {
    // Update the document description
    const content = components.find(v => v.path === window.location.pathname)?.content
    document.querySelector('meta[name="description"]').setAttribute('content', content || "");

  }, [window.location.pathname]);

  function RedirectTo({to}) {
    const [isLoading, setLoading] = useState(true);
   

    const navigate = useNavigate();
    

    
    useEffect(() => {
      const timer = setTimeout(() => {
        navigate(to, { replace: true });
        setLoading(false);
      }, 500);
  
      return () => clearTimeout(timer);
    }, [navigate]);
  
    return isLoading ? <Loading /> : null;
  }
  useEffect(() => {
    window.addEventListener('resize', function () {
      setHeight(document.getElementsByClassName('navbar')[0]?.clientHeight)
    });
    setHeight(document.getElementsByClassName('navbar')[0]?.clientHeight)
}, [])

// useEffect(() => {
//   setHeight(document.getElementsByClassName('navbar')[0]?.clientHeight)
// }, [])

useEffect(() => {
  console.log( userConsent, window.gtag);
  
      if (userConsent === true && window.gtag) { console.log( userConsent, window.gtag);
        window.gtag('config', 'AW-11159195842', {
          page_path: location.pathname,
        });
        console.log("Page view tracked:", location.pathname);
      } else {
        console.error("gtag is not defined");
      }
    }, [location, userConsent, window.gtag]);
 
  return (
    <div className="App" style={{}}>
      <Navbar />
      <div className='component-container' style={{marginTop: `${height}px`}}>
        <ModalBox></ModalBox>
        <Routes>
          {
            components.map((v, k) => {
              return (
                <Route key={k} exact path={v.path} element={v.component} />
              )
            })
          }
          <Route path="/" element={<RedirectTo to={'/home'} />} />
          <Route path="/home.html" element={<RedirectTo to={'/home'} />} />
          <Route path="/about.html" element={<RedirectTo to={'/about'} />} />
          <Route path="/work.html" element={<RedirectTo to={'/home'} />} />
          <Route path="/work/transition-renewable-energy.html" element={<RedirectTo to={'/home'} />} />
          <Route path="/work/digital-transformation.html" element={<RedirectTo to={'/home'} />} />
          <Route path="/work/aid-singapore-enterprises.html" element={<RedirectTo to={'/home'} />} />
          <Route path="/people.html" element={<RedirectTo to={'/about'} />} />
          <Route path="/esg.html" element={<RedirectTo to={'/home'} />} />
          <Route path="/press.html" element={<RedirectTo to={'/home'} />} />
          <Route path="/contact.html" element={<RedirectTo to={'/contact'} />} />
          <Route path="/news.html" element={<RedirectTo to={'/news'} />} />


        </Routes>
      </div>
      <Footer />
     


    </div>
  );
}

export default App;
